// https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-framework-extensions?tabs=react
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
    ApplicationInsights,
    type Snippet,
} from '@microsoft/applicationinsights-web';
import { TelemetryProviderFactory } from '@phx/instrumentation/web';

const AI_CLOUD_ROLE = 'ux-login-prescryptive';
const { VITE_APPLICATION_INSIGHTS_CONNECTION_STRING: connectionString } =
    import.meta.env;

export const createApplicationInsights = () => {
    const reactPlugin = new ReactPlugin();

    const snippet: Snippet = {
        config: {
            connectionString,
            correlationHeaderDomains: ['prescryptive.io'],
            enableCorsCorrelation: true,
            enableAutoRouteTracking: true,
            enableRequestHeaderTracking: true,
            extensions: [reactPlugin],
        },
    };
    const ai = new ApplicationInsights(snippet).loadAppInsights();

    ai.addTelemetryInitializer((envelope) => {
        if (envelope.tags === undefined) {
            envelope.tags = [];
        }
        envelope.tags['ai.cloud.role'] = AI_CLOUD_ROLE;
    });

    return { ai, reactPlugin };
};

export const telemetryInstance = TelemetryProviderFactory.getInstance({
    buildNumber: import.meta.env.VITE_BUILD_NUMBER,
    instrumentationKey: connectionString,
    cloudRole: AI_CLOUD_ROLE,
});
